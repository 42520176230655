import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Article } from '../../models/article.model';
import { CourseViewModel } from '../../models/course.model';
import { VideoGuideViewModel } from '../../models/video-guide.model';
import { FormatDurationPipe } from '../../shared/pipes/format-duration.pipe';
import { GlobalState } from '../../store/store';
import { getThumbnailUrl } from '../constants/utils';

@Injectable()
export class SEOService {
    private renderer2: Renderer2;
    private titleSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    private rawTitleSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
        null
    );

    public title$: Observable<string | null> = this.titleSubject.asObservable();
    public rawTitle$: Observable<string | null> = this.rawTitleSubject.asObservable();

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private metaService: Meta,
        private store: Store<GlobalState>,
        private title: Title,
        private rendererFactory: RendererFactory2,
        private durationPipe: FormatDurationPipe
    ) {
        this.renderer2 = rendererFactory.createRenderer(null, null);
    }

    private appendSuffix(newTitle: string) {
        return `${newTitle} - GameLeap`;
    }

    /**
     * Updates SEO meta tags.
     * @param metaData
     * @param metaData.url - the url to the shareable content
     * @param metaData.type - the type of the shareable content; types: https://developers.facebook.com/docs/reference/opengraph#object-type
     * @param metaData.title - the title of the shareable content
     * @param metaData.description - the description for the shareable content
     * @param metaData.imageUrl - the url for the thumbnail
     * @param metaData.imageWidth - the width of the thumbnail
     * @param metaData.imageHeight - the height of the thumbnail
     * @param metaData.videoDuration
     * @param metaData.videoReleaseDate
     * NOTE: The meta tags will be updated only for the provided properties in the metaData object.
     */
    public setTags(metaData: {
        url?: string;
        type?: string;
        title?: string;
        description?: string;
        imageUrl?: string;
        imageWidth?: number;
        imageHeight?: number;
        videoDuration?: number;
        videoReleaseDate?: string;
    }) {
        const propertyMappings = {
            url: ["property='og:url'"],
            type: ["property='og:type'"],
            title: ["property='og:title'", "property='title'", "property='twitter:title'"],
            description: [
                "property='og:description'",
                "property='description'",
                "property='twitter:description'",
                "name='description'"
            ],
            imageUrl: ["property='og:image'", "property='twitter:image'"],
            imageWidth: ["property='og:image:width'"],
            imageHeight: ["property='og:image:height'"],
            videoUrl: [
                "property='og:video'",
                "property='og:video:url'",
                "property='og:video:secure_url'"
            ],
            videoDuration: ["property='video:duration'"],
            videoReleaseDate: ["property='video:release_date'"]
        };
        metaData.description = metaData.description?.substring(0, 160);

        Object.keys(metaData).forEach(key => {
            propertyMappings[key].forEach(property => {
                if (metaData[key]) {
                    if (!this.metaService.getTag(property)) {
                        this.metaService.addTag(
                            {
                                name: property.split('=')[1].replace(/'/g, ''),
                                property: property.split('=')[1].replace(/'/g, ''),
                                content: metaData[key]
                            },
                            true
                        );
                    } else {
                        this.metaService.updateTag({ content: metaData[key] }, property);
                    }
                } else {
                    this.metaService.removeTag(property);
                }
            });
        });
    }

    /**
     * Get the title of the current HTML document.
     */
    public getTitle() {
        return this.title.getTitle();
    }

    /**
     * Set the title of the current HTML document.
     * @param newTitle
     */
    public setTitle(newTitle: string, appendSuffix: boolean = true) {
        const title = appendSuffix ? this.appendSuffix(newTitle) : newTitle;
        this.titleSubject.next(title);
        return this.title.setTitle(title);
    }

    /**
     * Set the raw title.
     * @param newTitle
     */
    public setRawTitle(newTitle: string) {
        this.rawTitleSubject.next(newTitle);
    }

    /**
     * Set canoncal URL in <head>.
     * @param newTitle
     */
    public setCanonicalUrl(url: string) {
        // strip the quary paramters
        url = url.split('?')[0];
        const head = this.document.getElementsByTagName('head')[0];
        var element: HTMLLinkElement | null = this.document.querySelector(`link[rel='canonical']`);
        if (element == null) {
            element = this.document.createElement('link') as HTMLLinkElement;
            head.appendChild(element);
        }
        element.setAttribute('rel', 'canonical');
        element.setAttribute('href', url);
    }

    /**
     *
     * !!!
     * !!! STRUCTURED MARKUP CODE BELOW
     * !!!
     *
     */

    private appendDOMElement(scriptContent: string, target?: ElementRef, id?: string) {
        const scriptId = 'seo-script-' + id;

        if (id && this.document.getElementById(scriptId) != null) {
            return;
        }

        const head = this.document.getElementsByTagName('head')[0];
        const node = this.document.createElement('script');
        node.classList.add('structured-data');
        node.type = 'application/ld+json';
        node.text = scriptContent;
        node.id = scriptId;

        // remove all other structured data nodes
        const structuredDataNodes = Array.from(
            this.document.getElementsByClassName('structured-data')
        );
        structuredDataNodes.forEach(node => {
            this.renderer2.removeChild(head, node);
        });
        // append the new and shiny structured data node
        this.renderer2.appendChild(head, node);
    }

    public removeItemScriptElement(target: ElementRef, id: string) {
        const scriptId = 'seo-script-' + id;
        const element = this.document.getElementById(scriptId);

        if (element == null) {
            return;
        }

        this.renderer2.removeChild(target.nativeElement, element);
    }

    /**
     * Creates and appends a rich structured data script for a news article.
     * Adheres to: https://developers.google.com/search/docs/appearance/structured-data/article
     * @param article
     */
    public appendArticleMarkup(article: Article, targetEl?: ElementRef) {
        const scriptContent = {
            '@context': 'https://schema.org',
            '@type': 'Article',
            headline: article.headline,
            alternativeHeadline: article.subheadline,
            image: [`${article.thumbnailPath}/feature/1x.webp`],
            dateCreated: article.createdAt,
            datePublished: article.createdAt,
            dateModified: article.updatedAt,
            articleBody: article.text,
            speakable: {
                '@context': 'https://schema.org',
                '@type': 'SpeakableSpecification',
                xpath: ['/html/head/title', '/html/head/meta[@name="description"]/@content']
            },
            author: [
                {
                    '@type': 'Person',
                    url: environment.clientRoot,
                    name: article.author.name
                },
                {
                    '@type': 'Organization',
                    url: environment.clientRoot,
                    name: 'GameLeap'
                }
            ],
            articleSection: 'Gaming',
            keywords: article.category.name,
            url: `${environment.clientRoot}/news/${article.slug}`,
            publisher: [
                {
                    '@type': 'Organization',
                    name: 'GameLeap',
                    logo: {
                        '@type': 'ImageObject',
                        url:
                            'https://www.gameleap.com/assets/images/header/gameleap_big_light_flat.png'
                    }
                }
            ]
        };
        this.appendDOMElement(JSON.stringify(scriptContent), targetEl, article._id);
    }

    /**
     * Creates and appends a rich structured data script for a course.
     * Adheres to: https://developers.google.com/search/docs/appearance/structured-data/course
     * @param course
     */
    public appendCourseMarkup(course: CourseViewModel, targetEl: ElementRef) {
        const scriptContent = {
            '@context': 'https://schema.org',
            '@type': 'Course',
            name: course.name,
            description: course.description,
            provider: {
                '@type': 'Organization',
                name: 'GameLeap - Gaming News, Guides and Tips',
                sameAs: 'https://www.gameleap.com'
            }
        };
        this.appendDOMElement(JSON.stringify(scriptContent), targetEl, course._id);
    }

    /**
     * Creates and appends a rich structured data script for a video object.
     * Adheres to: https://developers.google.com/search/docs/appearance/structured-data/video
     * @param videoGuide
     */
    public appendVideoMarkup(videoGuide: VideoGuideViewModel, targetEl: ElementRef) {
        const duration = this.durationPipe.getDuration(videoGuide.duration);
        // must be m3u8 or another supported format (mpd is not suppored right now)
        // https://developers.google.com/search/docs/appearance/video#supported-video-encodings
        let contentUrl: string;
        if (videoGuide.isFree) {
            contentUrl = videoGuide.sources.hlsPremium;
        } else {
            contentUrl = videoGuide.sources.hlsFree;
        }
        const scriptContent = {
            '@context': 'https://schema.org',
            '@type': 'VideoObject',
            name: videoGuide.title,
            description: videoGuide.description,
            thumbnailUrl: getThumbnailUrl(videoGuide.thumbnailPath, '1920x1080', 'jpeg'),
            contentUrl: contentUrl,
            uploadDate: videoGuide.releasedOn,
            duration: `PT${duration.hours}H${duration.minutes}M${duration.seconds}S`,
            genre: 'Gaming',
            provider: {
                '@type': 'Organization',
                name: 'GameLeap - Gaming News, Guides and Tips',
                sameAs: 'https://www.gameleap.com'
            },
            author: [
                {
                    '@type': 'Person',
                    name: videoGuide.player.name
                },
                {
                    '@type': 'Organization',
                    name: 'GameLeap'
                }
            ]
        };
        this.appendDOMElement(JSON.stringify(scriptContent), targetEl, videoGuide._id);
    }

    /**
     * Set canoncal URL in <head>.
     * @param newTitle
     */
    public preloadResource(url: string, as: string) {
        // query all link elements in the head where the href value is equal to the url to check it won't be a duplicate
        const linkElements = this.document.querySelectorAll(`link[href='${url}']`);
        if (linkElements.length > 0) {
            return;
        }
        const head = this.document.getElementsByTagName('head')[0];
        const element = this.document.createElement('link') as HTMLLinkElement;
        element.setAttribute('rel', 'preload');
        element.setAttribute('href', url);
        element.setAttribute('as', as);
        head.appendChild(element);
    }
}

